import React from 'react'
import { graphql } from 'gatsby'

import { Layout } from '../components/layout.jsx'
import { SEO } from '../components/seo.jsx'
import { PostSnippet } from '../components/post-snippet.jsx'

export default class BlogIndex extends React.Component {
  render () {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title='All posts'
          keywords={[`blog`]}
        />
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug
          return (
            <PostSnippet key={node.fields.slug} node={node} title={title} />
          )
        })}
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC },
      # nur Blogpostings
      # https://www.gatsbyjs.org/docs/graphql-reference/#filter
      filter: {
        fileAbsolutePath: {regex: "/blog/.*.md$/"},
        frontmatter: {draft: { ne: true }}
      }
      limit: 20
    ) {
      edges {
        node {
          # See https://www.gatsbyjs.org/docs/graphql-reference/#excerpt
          excerpt
          fields {
            slug
          }
          frontmatter {
            # See https://www.gatsbyjs.org/docs/graphql-reference/#dates
            date(formatString: "DD. MMMM YYYY", locale: "de-DE")
            title
            tags
            draft
            tracks { publicURL }
            coverImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                  presentationWidth
                }
              }
            }
          }
        }
      }
    }
  }
`
